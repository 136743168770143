import React, { useState } from "react";
import "./index.css";
import map from "../../assets/map.png";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    company: "",
    phoneNumber: "",
    emailAddress: "",
    service: "",
    products: "",
    subject: "",
    message: "",
    termsAccepted: false,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <div className="contact-us">
      <h3 className="contact-form-header -translate-y-5">Get In Touch</h3>
      <p className="desc -translate-y-5">
        We strive to respond to all inquiries as quickly as possible. Your
        questions and feedback are important to us, and we'll do our best to
        address them in a timely manner.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="form-design">
          <label style={{ color: "white" }}>Full Name</label>
          <input
            type="text"
            name="fullName"
            placeholder="Full name"
            value={formData.fullName}
            onChange={handleInputChange}
            style={{ width: 300, height: 40, color: "black" }}
          />
        </div>
        <div className="form-design">
          <label style={{ color: "white" }}>Company / Organization</label>
          <input
            type="text"
            name="company"
            placeholder="Company / Organization"
            value={formData.company}
            onChange={handleInputChange}
            style={{ width: 300, height: 40, color: "black" }}
          />
        </div>

        <div className="form-design">
          <label style={{ color: "white" }}>Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            style={{ width: 300, height: 40, color: "black" }}
          />
        </div>
        <div className="form-design">
          <label style={{ color: "white" }}>Email Address</label>
          <input
            type="email"
            name="emailAddress"
            placeholder="Email address"
            value={formData.emailAddress}
            onChange={handleInputChange}
            style={{ width: 300, height: 40, color: "black" }}
          />
        </div>

        <div className="form-design">
          <label style={{ color: "white" }}>Service</label>
          <input
            type="text"
            name="service"
            placeholder="Service"
            value={formData.service}
            onChange={handleInputChange}
            style={{ width: 300, height: 40, color: "black" }}
          />
        </div>
        <div className="form-design">
          <label style={{ color: "white" }}>Products</label>
          <input
            type="text"
            name="products"
            placeholder="Products "
            value={formData.products}
            onChange={handleInputChange}
            style={{ width: 300, height: 40, color: "black" }}
          />
        </div>

        <div className="form-design">
          <label style={{ color: "white" }}>Subject</label>
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleInputChange}
            style={{ width: 625, height: 40, color: "black" }}
          />
        </div>

        <div className="form-design">
          <label style={{ color: "white" }}>Message</label>
          <textarea
            name="message"
            placeholder="Write a message"
            value={formData.message}
            onChange={handleInputChange}
            style={{ width: 625, height: 200, color: "black" }}
          />
        </div>

        <div
          className="flex justify-between w-full"
          style={{ justifyContent: "space-between" }}>
          <label>
            <input
              type="checkbox"
              name="termsAccepted"
              checked={formData.termsAccepted}
              onChange={handleInputChange}
            />{" "}
            <span className="text-xs" style={{ color: "white" }}>
              I agree with terms of usage and privacy policy.
            </span>
          </label>
          <button
            className="rounded-md bg-gradient-to-r from-sky-400 to-blue-950 p-2 pl-10 pr-10"
            type="submit">
            Submit
          </button>
        </div>
      </form>

      <div className="where-to-find bg-sky">
        <p className="where-to-find-fact">Our Office</p>
        <h3 className="where-to-find-header">Here To Find</h3>
        <p className="where-to-find-desc">
          Experience our expansive network of offices across the world,
          connecting you to our exceptional services wherever you are.
        </p>
        <Link to="https://maps.app.goo.gl/x6Qw5kxUtuAfxuvo7?g_st=com.google.maps.preview.copy">
          <img src={map} alt="map" />
        </Link>
      </div>
    </div>
  );
};

export default ContactForm;
