import React from "react";
import Application from "../../assets/application.png";
import "../webDevelopment/index.css";
import { motion } from "framer-motion";

const Journey = () => {
  return (
    <div className="application-journey mt-36 bg-white our-services">
      <img
        src={Application}
        alt="website development"
        className="w-full md:hidden lg:hidden xl:hidden 2xl:hidden"
      />
      <div className="website-development w-full min-h-96 overflow-y-hidden justify-center pl-24 pr-24">
        <div className="flex website-development gap-5 flex-col w-full">
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: -20 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: -200 }}
            viewport={{ once: false, amount: 0.5 }}
            className="fact gradient">
            <p className="text-center w-full">Requirement Analysis</p>
            <div className="number">01</div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: 10 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: -200 }}
            viewport={{ once: false, amount: 0.5 }}
            className="fact white">
            <p className="text-center w-full">UI/UX Design</p>{" "}
            <div className="number gradient-number">02</div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: -200 }}
            whileInView={{ opacity: 1, x: -20 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: -200 }}
            viewport={{ once: false, amount: 0.5 }}
            className="fact gradient">
            <p className="text-center w-full">Development</p>
            <div className="number ">03</div>
          </motion.div>
        </div>

        <motion.img
          initial={{ opacity: 0, scale: 0.2 }}
          whileInView={{ opacity: 1, scale: 0.5 }}
          transition={{ duration: 2.5, ease: "easeInOut" }}
          exit={{ opacity: 0, scale: 0.2 }}
          viewport={{ once: false, amount: 0.5 }}
          src={Application}
          alt="app-development-journey"
          className="hidden sm:hidden md:w-full md:block"
        />

        <div className="flex flex-col w-full gap-5">
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: -200 }}
            viewport={{ once: false, amount: 0.5 }}
            className="fact gradient">
            <div className=" number">04</div>
            <p className="text-center w-full">Testing</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: -200 }}
            viewport={{ once: false, amount: 0.5 }}
            className="fact white">
            <div className="number gradient-number">05</div>
            <p className="text-center w-full">Deployment</p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            exit={{ opacity: 0, x: -200 }}
            viewport={{ once: false, amount: 0.5 }}
            className="fact gradient">
            <div className="number">06</div>
            <p className="text-center w-full">Maintenance and Updates</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Journey;
