import React from "react";
import { Layout } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import footerLogo from "../assets/footerLogo.png";
import "./index.css";
import { Link } from "react-router-dom";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { HiOutlinePhone } from "react-icons/hi2";

// const { Footer } = Layout;

const App = () => {
  return (
    <div>
      <Layout>
        <div className="footer-group pl-5  flex flex-col justify-evenly center  sm:flex-col md:flex-row lg:flex-row xl:flex-row pb-20">
          <div className="logo-social-media-group m-3">
            <img src={footerLogo} alt="footerLogo" />
            <div className="social-media-group">
              <Link to="https://www.facebook.com/originbusinesssolutions?mibextid=ZbWKwL">
                <FontAwesomeIcon
                  style={{ width: 20, height: 20, marginRight: "10px" }}
                  icon={faFacebook}
                />
              </Link>
              <Link to="https://www.linkedin.com/company/originbusinesssolutions/">
                <FontAwesomeIcon
                  style={{ width: 20, height: 20, marginRight: "10px" }}
                  icon={faLinkedin}
                />
              </Link>
              <FontAwesomeIcon
                style={{ width: 20, height: 20, marginRight: "10px" }}
                icon={faYoutube}
              />
            </div>
          </div>

          <div className="services m-3">
            <h4 className="text-xl">SERVICES</h4>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="/web-development"> Web Development</Link>
            </p>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="application-development"> Application Development</Link>
            </p>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="software-development"> Software Development</Link>{" "}
            </p>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="software-outscoring"> Software Outsourcing</Link>
            </p>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="cloud-hosting"> Clout Hosting & Managed Services</Link>
            </p>
          </div>

          <div className="products m-3">
            <h4 className="text-xl"> PRODUCTS</h4>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="ecommerce"> OBS E-Commerce</Link>
            </p>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="tenant"> OBS Tenant-Property Management System</Link>{" "}
            </p>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="loyalty"> OBS Loyalty & Membership System</Link>
            </p>
            <p className="mt-3 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              <Link to="solutions"> OBS Payment Solutions</Link>{" "}
            </p>
          </div>

          <div className="solutions m-3">
            <h4 className="text-xl">SOLUTIONS</h4>
            <p className="mt-5 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              Use Cases
            </p>
            <p className="mt-5 hover:border-b-2 hover:duration-1000 hover:pb-2 w-2/3 cursor-pointer">
              Industries
            </p>
          </div>

          <div className="contact m-3">
            <h4 className="text-xl">CONTACT</h4>{" "}
            <div className="flex mt-5 gap-2 flex-row align-center">
              <div className="mr-3">
                <HiOutlinePhone size={20} />
              </div>
              <div>
                (+959) 97 5237 7778 <br />
                (+959) 97 5237 7779
              </div>
            </div>{" "}
            <div className="flex flex-row mt-5 gap-2 align-center">
              <div className="mr-3">
                {" "}
                <CiMail size={20} />
              </div>
              <div>hr@obs.com.mm</div>
            </div>
            <div className="flex flex-row mt-5 gap-2  align-center">
              <div>
                <CiLocationOn size={20} style={{ marginRight: "30px" }} />
              </div>
              <div className="address">
                No(5-D), 99 Condo, Dhammazedi Road, Kamaryut Township, Yangon
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
            alignItems: "center",
            height: "50px",
            paddingTop: "15px",

            background:
              "linear-gradient(102.28deg, #00BCE8 -12.02%, #012D67 77.71%)",
            color: "white",
          }}>
          <div className="text-xl">
            {" "}
            © 2024 Origin Business Solution. All rights reserved.
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default App;
